/* VISUALIZER */

#synth-viz {
  margin: 20px auto;
  height: 400px;
  width: 90%;
}

/* SYNTH CONTROLS */
#synth-section {
  width: 90%;
  text-align: center;
  margin: 0 auto;

  background-image: repeating-linear-gradient(0deg, rgba(255,255,255, 0.25), rgba(255,255,255, 0.25) 1px, transparent 1px, transparent 7px);
    background-size: 2px;
}

#synth-component {
    background-image: repeating-linear-gradient(36deg, rgba(255,255,255, 0.25), rgba(255,255,255, 0.25) 1px, transparent 0px, transparent 2px);
    background-size: 12px;
}

/* SLIDERS */
.slider-section {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  background-color: rgba(68,68,68, .5);
}

.slider-holder {
  margin: 0 2px 20px 20px;
  padding: 30px 10px 10px 0;
  border: rgb(34,34,34) 1px dotted;
  border-radius: 5px;
  width: 25%;
  min-width: 200px;

  background-color: rgba(101,101,101, .5);
}

.sliders {
  -webkit-appearance: none;
  height: 20px;
  max-width: 200px;
  background: #000;
  outline: none;
  border: 5px solid rgb(51,51,51);
  border-radius: 10px;
  margin: 0 0 50px 20px;
}

#vol-d {
  background-image: url(./img/volume.png);
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: 60% 78%;
}

#wave-d {
  background-image: url(./img/waveforms_w.png);
  background-size: 73%;
  background-repeat: no-repeat;
  background-position: 60% 75%;
}

#mode-d {
  background-image: url(./img/mode.png);
  background-size: 73%;
  background-repeat: no-repeat;
  background-position: 62% 80%;
}

#hold-d {
  background-image: url(./img/hold.png);
  background-size: 73%;
  background-repeat: no-repeat;
  background-position: 70% 75%;
}

/* for chrome/safari */
.sliders::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 35px;
  background: rgb(34,34,34);
  cursor: url(./img/cursor.png), auto;
  border: 2px solid rgb(135,135,135);
  border-radius: 5px;
}

/* for firefox */
.sliders::-moz-range-thumb {
  width: 20px;
  height: 40px;
  background: rgb(34,34,34);
  cursor: url(./img/cursor.png), auto;
  border: 2px solid rgb(135,135,135);
  border-radius: 5px;
}

#key-container {
  height: 50px;
  background-color: rgb(34,34,34);
  display: flex;
  justify-content: space-between;

  background-image: repeating-linear-gradient(0deg, rgba(255,255,255, 0.25), rgba(255,255,255, 0.25) 1px, transparent 1px, transparent 7px);
    background-size: 2px;
}

.synth-key {
  list-style: none;
  background-color: rgb(135,135,135);
  border-radius: 2px;
  color: black;
  width: 40px;
  height: 60px;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  margin-right: 20px;
  cursor: url(./img/cursor.png), auto;
    background-image: repeating-linear-gradient(90deg, rgba(255,255,255, 0.25), rgba(255,255,255, 0.25) 1px, transparent 1px, transparent 10px);
    background-size: 2px;

}

@keyframes fadeOut {
  from {
    background-color: yellow;
  } 
  to {
    background-color: transparent;
  }
}

@keyframes fadeIn {
  from {
    background-color: transparent;
  } 
  to {
    background-color: pink;
  }
}


/* SCREEN WIDTH 768px+ */
@media screen and (min-width: 768px)
{
  .slider-holder {
    margin: 0 auto;
  }
  
}

/* SCREEN WIDTH 1200px+ */
@media screen and (min-width: 1200px)
{

  #synth-section {
    width: 70%;
  }

  #vol-d {
    background-size: 58%;
    background-position: 60% 78%;
  }

  #wave-d {
    background-size: 65%;
    background-position: 58% 75%;
  }

  #mode-d {
    background-size: 60%;
    background-position: 58% 80%;
  }

  #hold-d {
    background-size: 60%;
    background-position: 60% 75%;
  }
}
