.filter-section {
	min-height: 310px;
	margin-bottom: 60px;
}

/* Project Filter Buttons */
.filter-project-buttons {
	padding-right: 20px;
	height: 310px;
	border-right: 8px solid #222222;
}

.filter-buttons {
	background-color: #a9a9a9;
	color: black;
	margin-bottom: 10px;
}

.filter-buttons:hover {
	background: transparent;
	cursor: url(./img/cursor.png), auto;
}

.filter-buttons:focus {
	background-color: #222222;
}

/* Filter Preview Section */
.filter-section-header
{
	font-family: mono45-headline, monospace;
	font-size: 1.5em;
	color: #EC449B;
	margin-bottom: 10px;
}

.filter-card-holder {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	max-height: 310px;
}

/* Scrollbar */
.filter-card-holder::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a9a9a9;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #EC449B;
  border-radius: 8px;
}

.card-container {
	max-height: 100px;
	background-color: white;
}

.card-filter-preview-feature {
	background-color: #767676;
	margin: 0px 20px 20px 0;
	padding: 10px 10px 1px 10px;
	min-height: 120px;
	max-width: 280px;
	text-align: center;
    border-top: 5px solid #99F443;
}

.card-filter-preview-main {
	background-color: #444444;
	margin: 0px 20px 20px 0;
	padding: 5px 20px 5px 20px;
	max-width: 280px;
	text-align: center;
}

.card-filter-preview {
	background-color: #333333;
	margin: 0px 20px 20px 0;
	padding: 18px 20px 5px 20px;
	max-width: 280px;
	text-align: center;

	border-right: 10px solid transparent;
      border-top: 10px solid #222222;
      border-left: 10px solid #222222;
      border-bottom: 10px solid #222222;
      border-right: 10px solid #222222;
      border-top-left-radius: 160px;
      border-top-right-radius: 120px;
      border-bottom-left-radius: 190px;
      border-bottom-right-radius: 190px;
}

.card-filter-preview-collab {
	margin: 0px 20px 20px 0;
	padding: 5px 20px 5px 20px;
	max-width: 280px;
	text-align: center;
	border-radius: none;
	background: repeating-linear-gradient(
		-55deg,
		#222,
		#222 10px,
		#333 10px,
		#333 20px
	);
}

.card-filter-preview:hover, .card-filter-preview-main:hover, .card-filter-preview-feature:hover, .card-filter-preview-collab:hover
 {
	color: white;
	cursor: url(./img/cursor.png), auto;
	background:transparent;
	border: 1px solid #99F443;
}

.sm-card-text-area {
	color: white;
}

.sm-card-title {
	font-size: 1.2em;
}

.sm-card-name {

}

/* Song Playing Animation */
@keyframes background-anim {
	from {
		background: rgba(236,68,155);
	}
	to {
		background: rgba(153,244,67);
	}
}

@keyframes border-anim {
	from {
		border: 20px solid rgba(236,68,155);
	}
	to {
		border: 20px solid rgba(153,244,67);
	}
}

/* Selected Project Section */
.card-detail-holder {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	min-height: 400px;
	border: 2px solid #99F443;
	padding: 60px;

}

.audio-preview-overlay {
	width: 100%;
	height: 100%;
	animation: background-anim 20s infinite alternate;
}

.audio-preview-overlay:hover {
	animation: none;
}

.audio-preview-overlay:focus {
	animation-duration: 10s;
}

.card-song-preview {
	text-align: center;

}

.card-song-preview-iframe {
	border: 0px;
	width: 350px;
	height: 350px;
	margin: auto;
}

.card-detail-area {
	background-color: #111111;
	color: white;
	padding-top: 20px;
	padding-left: 20px;
	min-width: 240px;

}

.card-info {
	margin-bottom: 20px;
}

.card-info a:link, a:visited {
	color: #99F443;
}

.card-info a:hover {
	color: white;
	background-color: #222222;
	cursor: url(./img/cursor.png), auto;
}



