/* color palette
	lightest - rgb(169,169,169);
	dark gray - rgb(34,34,34);
	black - rgb(17,17,17);
	rgb(152,152,152);	rgb(135,135,135);	rgb(118,118,118);	rgb(101,101,101);	
	rgb(85,85,85);	rgb(68,68,68);	rgb(51,51,51);

	pink EC449B
	green 99F443

	night cows
	blue 090a1e
	green 125102
	stars ffffff
*/

/* entire audio player component */
.audio-player-holder {
	
}

/* graph section */
.audio-analyzer-holder {
	padding: 0px 0px 10px 0px;
}

.audio-analyzer-frame {

}

.audio-visualizer {
	width: 100%;
	height: 300px;
	background-color: rgba(51,51,51,.2);
	z-index: 2;
	position: relative;
}

/* buttons and controls */
.audio-player-controls {
	margin-bottom: 50px;
	padding: 3px 0 3px 50px;
	background-color: rgb(51,51,51);
	font-weight: 300;
	z-index: 2;
	position: relative;
}

#play-pause-audio {
	background-color: rgb(118,118,118);
	margin-right: 3px;
	cursor: url(./img/cursor.png), auto;
}

#stop-audio, #player-dropdown {
	background-color: rgb(85,85,85);
	margin-right: 3px;
	font-family: mono45-headline, monospace;
	cursor: url(./img/cursor.png), auto;
}

#play-pause-audio:hover, #stop-audio:hover, #player-dropdown:hover {
	background-color: rgb(169,169,169);
}

#track-playing-name {
	padding-left: 10px;
	padding-top: 5px;
}

/* entire album cover section */
.feature-album-holder {
	width: 100%;
	padding-bottom: 50px;
	z-index: 1;
	position: relative;
}

.feature-album-preview {
	cursor: url(./img/cursor_default.png), auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.feature-album-image {
	background-position: 50% 50%;
	background-size: 100%;
	height: 450px;
	width: 450px;
	margin: 50px 0 50px 0;
	padding: 5px;
	cursor: url(./img/cursor.png), auto;
}

.first-cover {
	border: #99F443 5px solid;
	
}

.second-cover {

}

.third-cover {

}

/* stars and sky */
@keyframes move-background {
  from {
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	to { 
		-webkit-transform: translate3d(1000px, 0px, 0px);
	}
}
@-webkit-keyframes move-background {
  from {
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	to { 
		-webkit-transform: translate3d(1000px, 0px, 0px);
	}
}

@-moz-keyframes move-background {    
	from {
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	to { 
		-webkit-transform: translate3d(1000px, 0px, 0px);
	}
}

    @-webkit-keyframes move-background {
	from {
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	to { 
		-webkit-transform: translate3d(1000px, 0px, 0px);
	}
}

.background-container{
	position: fixed;
	top: 0;
	left:0;
	bottom: 0;
	right: 0;
}

.stars {
 background: black url(./img/stars2.png) repeat;
 position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
	z-index: -2;
}

.twinkling{
	width:10000px;
	height: 100%;
	background: transparent url(./img/twinkling2.png) repeat;
	background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    
    -moz-animation:move-background 70s linear infinite;
  -ms-animation:move-background 70s linear infinite;
  -o-animation:move-background 70s linear infinite;
  -webkit-animation:move-background 70s linear infinite;
  animation:move-background 70s linear infinite;
	
}

