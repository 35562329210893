@import url("https://use.typekit.net/dkj2ybr.css");
/* fonts
	Mono45 Headline Bold
font-family: mono45-headline, monospace;
font-weight: 700;
font-style: normal;

Mono45 Headline Light
font-family: mono45-headline, monospace;
font-weight: 300;
font-style: normal;

Eurostile Regular
font-family: eurostile, sans-serif;
font-weight: 400;
font-style: normal;

Eurostile Bold
font-family: eurostile, sans-serif;
font-weight: 700;
font-style: normal;

Eurostile Regular Oblique
font-family: eurostile, sans-serif;
font-weight: 400;
font-style: italic;

Eurostile Bold Oblique
font-family: eurostile, sans-serif;
font-weight: 700;
font-style: italic;

Eurostile Cond Regular
font-family: eurostile-condensed, sans-serif;
font-weight: 400;
font-style: normal;

Eurostile Cond Heavy
font-family: eurostile-condensed, sans-serif;
font-weight: 800;
font-style: normal;

Eurostile Cond Regular Italic
font-family: eurostile-condensed, sans-serif;
font-weight: 400;
font-style: italic;

Eurostile Cond Heavy Italic
font-family: eurostile-condensed, sans-serif;
font-weight: 800;
font-style: italic;

Eurostile Extd Regular
font-family: eurostile-extended, sans-serif;
font-weight: 400;
font-style: normal;

Eurostile Extd Black
font-family: eurostile-extended, sans-serif;
font-weight: 900;
font-style: normal;

Eurostile Extd Regular Italic
font-family: eurostile-extended, sans-serif;
font-weight: 400;
font-style: italic;

Eurostile Extd Black Italic
font-family: eurostile-extended, sans-serif;
font-weight: 900;
font-style: italic;
*/
/* color palette
	lightest - rgb(169,169,169);
	dark gray - rgb(34,34,34);
	black - rgb(17,17,17);
	rgb(152,152,152);	rgb(135,135,135);	rgb(118,118,118);	rgb(101,101,101);	
	rgb(85,85,85);	rgb(68,68,68);	rgb(51,51,51);

	pink EC449B
	green 99F443
*/
.App {
	margin: 0 auto;
	cursor: url(./img/cursor_default.png), auto;
}

body {
    background-color: #000000;
    color: white;
    font-size: 16px;
    padding: 0px;
    margin: 0px;
    font-family: eurostile, sans-serif;
}

header {
	margin-bottom: 60px;
	height: 80px;
	font-family: mono45-headline, monospace;
	color: #a9a9a9;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

footer {
	background-color: rgb(24,26,27);
	padding-top: 40px;
	padding-bottom: 40px;
	position: relative;
	z-index: 2;

}

h1, h2, h3, h4, h5 {
	font-family: mono45-headline, monospace;
}

/* Nav Bar */
#logo {
	background-position: center center;
	background-size: cover;
	height: 40px;
	max-width: 100%;
	max-height: 100%;
}

.App-logo {
	padding: 20px 0px 0px 20px;
}

.App-nav {
	color: white;
	height: 30px;
	margin-top: 20px;
}

.app-nav-section {
	display: flex;
}

.App-nav-item {
	font-size: 24px;
	border-bottom: 4px solid transparent;
	margin-bottom: 5px;
	padding: 20px 30px 10px 0px;
	cursor: url(./img/cursor.png), auto;
}

#nav-olf {
	color: rgb(169,169,169);
}

#nav-audio {
	color: rgb(135,135,135);
}

#nav-wtf {
	color: rgb(101,101,101);
}

#nav-olf:hover, #nav-audio:hover, #nav-wtf:hover {
	color: #99F443;
}

/* Hero Image area */
.image-area {
	background-position: center center;
	background-size: cover;
	padding-top: 200px;
	padding-bottom: 100px;
}

.image-area-audio {
	background-position: center center;
	background-size: cover;
	padding-top: 100px;
	
}

.centered-container {
	max-width: 1020px;
	margin: 0px auto;
}

.page-header {
	text-align: center;
	color: #555555;
	font-size: 2.5rem;
	font-family: mono45-headline, monospace;
}

.intro-text {
	font-size: 1.5rem;
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
	max-width: 85%;
}

/* visualization */
#graph {
	margin-top: 10px;
}

/* cards */
.audio-card-columns {
	display: flex;
	flex-wrap: wrap;
}

.audio-card {
	width: 50%;
	height: 300px;
}





.App-content {

}

/* FOOTER */

#footer-home, #footer-olf, #footer-audio {
	font-family: mono45-headline, monospace;
	text-decoration: none;
	color: white;
	margin-bottom: -30px;
}

#footer-other {
	font-family: mono45-headline, monospace;
	text-decoration: none;
	color: white;
}

.footer-nav-other-item {
	margin-bottom: -10px;
}

.App-footer a:link, .App-footer a:visited {
	color: white;
}

#footer-home:hover, #footer-olf:hover, #footer-audio:hover, #footer-other:hover, .App-footer a:hover {
	color: #99F443;
	cursor: url(./img/cursor.png), auto;
}

#copyright {
	padding-top: 50px;
	margin-left: 100px;
	font-size: .8rem;
}

/* AUDIO PLAYER */
.audio-player-holder {
	padding: 0px;
	
}

.app-audio-player {
	width: 80%;
	background-color: hotpink;
}

/* visualization */
#graph {
	margin-top: 10px;
}


/* SCREEN WIDTH 768px+ */
@media screen and (min-width: 768px)
{
	Nav {


	}

	.App-nav ul {
		text-align: right;
	}

	.App-nav ul li {
		display: inline-block;
		border-bottom: 0px;
		border-left: 1px solid rgb(51,51,51);
		text-align: left;
	}
}

/* SCREEN WIDTH 1200px+ */
@media screen and (min-width: 1200px)
{

}